var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-sheet',{attrs:{"color":"appBackground"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):(!_vm.sortedAgents && _vm.queryFailed)?_c('v-sheet',{staticClass:"text-subtitle-1 font-weight-light"},[_c('v-alert',{attrs:{"border":"left","colored-border":"","elevation":"2","type":"error","max-width":"600"}},[_c('p',[_vm._v(" Something went wrong while trying to fetch agents. ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Please try refreshing your page. If this error persists, return to this page after a few minutes. ")])])],1):_c('v-sheet',{attrs:{"color":"appBackground"}},[_c('SubPageNav',{attrs:{"icon":"pi-agent","page-type":"Agents"}},[_c('span',{style:(_vm.loading > 0
          ? {
              display: 'block',
              height: '28px',
              overflow: 'hidden'
            }
          : _vm.$vuetify.breakpoint.smAndDown && {
              display: 'inline'
            }),attrs:{"slot":"page-title"},slot:"page-title"},[(_vm.loading === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.tenant.name)+" ")]):_c('span',[_c('v-skeleton-loader',{attrs:{"type":"heading","tile":""}})],1)]),_c('span',{class:{ 'mx-auto': _vm.$vuetify.breakpoint.xsOnly },attrs:{"slot":"page-actions"},slot:"page-actions"},[_c('v-dialog',{attrs:{"width":"25%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(_vm.oldAgents)?_c('v-btn',_vm._g({staticClass:"vertical-button py-1 ",attrs:{"color":"red","text":"","tile":"","small":""}},on),[_c('v-icon',[_vm._v(" delete_sweep ")]),_c('div',{staticClass:"mb-1"},[_vm._v("Clean Up")])],1):_vm._e()]}}]),model:{value:(_vm.cleanUpDialog),callback:function ($$v) {_vm.cleanUpDialog=$$v},expression:"cleanUpDialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-h6 word-break-normal"},[_vm._v(" Clean up unhealthy agents? ")]),_c('v-card-text',[_c('p',[_vm._v(" This will remove any agents that haven't queried "),(_vm.isCloud)?_c('span',{staticClass:"primary--text"},[_vm._v("Prefect Cloud")]):_c('span',{staticClass:"secondaryGray--text"},[_vm._v("Prefect Server")]),_vm._v(" in the last "+_vm._s(_vm.unhealthyThreshold / 60)+" hours. ")]),_c('p',[_vm._v(" Note: These agents will appear again if they become healthy by querying the server. ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","tile":""},on:{"click":function($event){_vm.cleanUpDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"loading":_vm.clearingAgents,"dark":"","color":"red","depressed":""},on:{"click":_vm.clearUnhealthyAgents}},[_vm._v(" Confirm ")])],1)],1)],1),(_vm.sortedAgents && _vm.sortedAgents.length)?_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","left":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"vertical-button py-1",attrs:{"text":"","tile":"","small":""}},on),[_c('v-icon',[_vm._v(" filter_list ")]),_c('div',{staticClass:"mb-1"},[_vm._v("Filter")])],1)]}}],null,false,2298045483),model:{value:(_vm.filterMenuOpen),callback:function ($$v) {_vm.filterMenuOpen=$$v},expression:"filterMenuOpen"}},[_c('v-card',{attrs:{"width":"320"}},[_c('v-card-text',{staticClass:"pb-6"},[_c('v-autocomplete',{ref:"agents",attrs:{"data-public":"","items":_vm.allLabels,"label":"Filter agents by label","outlined":"","multiple":"","chips":"","small-chips":"","disabled":_vm.showUnlabeledAgentsOnly || _vm.allLabels.length === 0,"deletable-chips":"","hide-no-data":"","menu-props":{
                closeOnContentClick: true,
                maxHeight: 300,
                transition: 'slide-y-transition'
              }},on:{"click:append":_vm.menuArrow},model:{value:(_vm.labelInput),callback:function ($$v) {_vm.labelInput=$$v},expression:"labelInput"}}),_c('v-switch',{staticClass:"ma-0 mt-1 label-switch-position",attrs:{"label":"Only show agents with no labels","hide-details":""},model:{value:(_vm.showUnlabeledAgentsOnly),callback:function ($$v) {_vm.showUnlabeledAgentsOnly=$$v},expression:"showUnlabeledAgentsOnly"}}),_c('v-checkbox',{attrs:{"hide-details":"","label":"Show healthy agents","value":"healthy","color":"success"},model:{value:(_vm.statusInput),callback:function ($$v) {_vm.statusInput=$$v},expression:"statusInput"}}),_c('v-checkbox',{attrs:{"label":"Show stale agents","hint":("Stale agents have not queried for flows in the last " + (_vm.staleThreshold === 1
                    ? 'minute'
                    : (_vm.staleThreshold + " minutes")) + "."),"persistent-hint":"","value":"stale","color":"warning"},model:{value:(_vm.statusInput),callback:function ($$v) {_vm.statusInput=$$v},expression:"statusInput"}}),_c('v-checkbox',{attrs:{"label":"Show unhealthy agents","hint":("Unhealthy agents have not queried for flows in the last " + (_vm.unhealthyThreshold === 1
                    ? 'minute'
                    : ((_vm.unhealthyThreshold / 60) + " hours")) + "."),"persistent-hint":"","value":"unhealthy","color":"utilGrayLight"},model:{value:(_vm.statusInput),callback:function ($$v) {_vm.statusInput=$$v},expression:"statusInput"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.resetFilter}},[_vm._v(" Reset ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.filterMenuOpen = false}}},[_vm._v(" Close ")])],1)],1)],1):_vm._e()],1)]),_c('v-scroll-x-reverse-transition',[(_vm.filtersApplied && !_vm.filterMenuOpen)?_c('v-alert',{staticClass:"filter-alert-position",attrs:{"border":"left","colored-border":"","dense":"","elevation":"2","type":"info"}},[_c('span',[_vm._v(" Filters applied. ")]),_c('v-btn',{staticClass:"ml-3 mr-0 my-0 pa-0",attrs:{"tile":"","text":"","small":"","color":"info"},on:{"click":function($event){_vm.filterMenuOpen = true}}},[_vm._v("View Filters")])],1):_vm._e()],1),_c('v-tabs-items',{staticClass:"px-6 mx-auto tabs-border-bottom tab-full-height",staticStyle:{"max-width":"1440px"},style:({
      'padding-top': _vm.$vuetify.breakpoint.smOnly ? '80px' : '100px'
    }),attrs:{"tag":"div"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"pa-0",attrs:{"value":"overview","transition":"tab-fade","reverse-transition":"tab-fade"}},[(_vm.filteredAgents && _vm.filteredAgents.length > 0)?_c('v-row',{style:({
          'padding-top': _vm.$vuetify.breakpoint.smOnly ? '10px' : '1px',
          'padding-bottom': _vm.$vuetify.breakpoint.smOnly ? '10px' : '10px'
        })},_vm._l((_vm.filteredAgents),function(agent){return _c('v-col',{key:agent.id,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('AgentTile',{attrs:{"raw-agent":agent,"selected-labels":_vm.labelInput},on:{"label-click":_vm.handleLabelClick}})],1)}),1):(_vm.filtersApplied)?_c('v-row',{staticClass:"ma-8"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center position-absolute center-absolute text-h5 utilGrayDark--text",staticStyle:{"z-index":"1"}},[_vm._v(" No agents found. Try removing a filter. ")])])],1):_c('v-row',{staticClass:"ma-8"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center position-absolute center-absolute text-h5 utilGrayDark--text",staticStyle:{"z-index":"1"}},[_vm._v(" You have no agents querying for runs. For help setting up an agent, check out the agent "),_c('a',{attrs:{"href":"https://docs.prefect.io/orchestration/agents/overview.html","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("docs")]),_vm._v(" or "),_c('router-link',{staticClass:"link",attrs:{"to":'/tutorial/Universal-Deploy'}},[_c('u',[_vm._v("tutorial")])]),_vm._v(". ")],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }